import React, {useState, useEffect, useRef} from 'react';
import LazyLoad from 'react-lazyload';
import { Button, Table, Image } from 'react-bootstrap';
import './styles.css'; // Importe o arquivo CSS
import previousIcon from './Icons/previousIcon.png'; // O caminho para o ícone anterior
import nextIcon from './Icons/nextIcon.png'; // O caminho para o ícone próximo
import pauseIcon from './Icons/pauseIcon.png';
import fixarIcon from './Icons/fixarIcon.png';
import infiniteplayIcon from './Icons/infiniteplayIcon.png';
import { verbos1, verbos2, verbos3, verbos4, verbos5, verbos6, verbos7, tempos1, tempos2, tempos3, tempos4, tempos5, tempos6, tempos7, Verbos, Tempos, VerbosTempos, CorrigirTempo } from './conjugacao1';
import {paradigmas, Terminacoes, Pagina, VerbosTemposParaVerbosS} from "./paradigmas";
import {
    playerIsPaused,
    playerIsPlaying,
    playerNewList,
    playerPause,
    playerPlay,
    playerPlayAndWait,
    playerSpeed,
    playerStopAndReset
} from "./musicPlayer";
// import logo from './logotipo2.png'; // O caminho para a imagem
// import paypalIcon from "./Icons/paypalIcon.png";

const ActivityBoard = ({ goBack, selection }) => {
    const logoRef=useRef('./logotipo2.png');
    const paypalIconRef=useRef('./Icons/paypalIcon.png');

    ////Variáveis do quadro principal
    const ordemVerbosRef = useRef(0);
    const ordemTemposRef = useRef(0);
    const titleRef = useRef('Regulares e Irregulares');
    const numeroTemposRef = useRef(0);
    const numeroVerbosRef = useRef(0);
    const verboRef = useRef('AMAR (To love)');
    const verboPRef =useRef('AMAR');
    const verboIRef =useRef('(To love)');
    const verboTRef =useRef ('(-AR)');
    const tempoRef = useRef('Presente');
    const arquivoRef = useRef("logotipo2");
    const cBFixarVerboRef = useRef(false);
    const cBFixarTempoRef = useRef(false);
    const cBSPassarVerboRef = useRef(false);
    const cBSPassarTempoRef = useRef(false);
    const bPausarRef  = useRef(false);
    const bRepetirRef  = useRef(false);
    const conjugacaoVerbosRef = useRef(verbos1);
    const conjugacaoTemposRef = useRef(tempos1);
    const audiosUrlRef = useRef([]); // Adicione esta referência no início do seu componente
    const audiosRef = useRef([]);
    //// Variáveis do quadro de seguidores de paradigmas
    const arquivoSRef = useRef("logotipo2");
    const verboSRef = useRef('-AR');
    const paginaSRef = useRef('(1/1)');
    const listaPVerbosSRef =useRef(paradigmas);
    const listaVerboSRef = useRef(paradigmas);
    const ordemVerbosSRef = useRef(0);
    //// Variáveis de ambos os quadros
    const [renderSt, setRender] = useState(0);
    const loadingRef = useRef(false);
    const hasRunRef = useRef(false);

    //// Funções do quadro principal
    const atualizarVetorVerbosS = () => {
        let verboParadigma= VerbosTemposParaVerbosS(conjugacaoVerbosRef.current[ordemVerbosRef.current]).substring(0,3);
        listaVerboSRef.current=listaPVerbosSRef.current.filter(item=> item.startsWith(verboParadigma));
    }

    // Função para atualizar os tempos
    const atualizarTempos = async () => {
        if (ordemVerbosRef.current >= conjugacaoVerbosRef.current.length) {
            ordemVerbosRef.current=0;
        }
        arquivoRef.current=conjugacaoVerbosRef.current[ordemVerbosRef.current].substring(0, 5);

        verboRef.current=Verbos(conjugacaoVerbosRef.current[ordemVerbosRef.current]);
        verboPRef.current=verboRef.current.substring(0,verboRef.current.indexOf(" "));
        verboIRef.current=verboRef.current.substring(verboRef.current.indexOf(" ")+1);
        verboTRef.current=verboIRef.current.substring(verboIRef.current.indexOf(" ")+1);
        verboIRef.current=verboIRef.current.substring(0,verboIRef.current.indexOf(" "));
        tempoRef.current=CorrigirTempo(Tempos(conjugacaoVerbosRef.current[ordemVerbosRef.current]));

        // Atualize OrdemTempos

        ordemTemposRef.current= conjugacaoTemposRef.current.findIndex(tempoX => VerbosTempos(tempoX) === conjugacaoVerbosRef.current[ordemVerbosRef.current]);
        atualizarVetorVerbosS();
        ordemVerbosSRef.current=listaVerboSRef.current.findIndex(verboSX => verboSX.substring(0,3)===VerbosTemposParaVerbosS(conjugacaoVerbosRef.current[ordemVerbosRef.current]).substring(0,3));
    };

    // Função para atualizar os verbos
    const atualizarVerbos = async () => {
        if (ordemTemposRef.current >= conjugacaoTemposRef.current.length) {
            ordemTemposRef.current = 0;
        }

        arquivoRef.current=VerbosTempos(conjugacaoTemposRef.current[ordemTemposRef.current]).substring(0, 5);
        verboRef.current=Verbos(conjugacaoTemposRef.current[ordemTemposRef.current]);
        verboPRef.current=verboRef.current.substring(0,verboRef.current.indexOf(" "));
        verboIRef.current=verboRef.current.substring(verboRef.current.indexOf(" ")+1);
        verboTRef.current=verboIRef.current.substring(verboIRef.current.indexOf(" ")+1);
        verboIRef.current=verboIRef.current.substring(0,verboIRef.current.indexOf(" "));
        tempoRef.current=CorrigirTempo(Tempos(conjugacaoTemposRef.current[ordemTemposRef.current]));

        // Atualize OrdemVerbos
        ordemVerbosRef.current = conjugacaoVerbosRef.current.findIndex(verboX => VerbosTempos(verboX) === conjugacaoTemposRef.current[ordemTemposRef.current]);
        atualizarVetorVerbosS();
        ordemVerbosSRef.current=listaVerboSRef.current.findIndex(verboSX => verboSX.substring(0,3)===VerbosTemposParaVerbosS(conjugacaoTemposRef.current[ordemTemposRef.current]).substring(0,3));
    };
    const pararAudios = async () => {
        try {
            await playerStopAndReset();
            // configure seu player mais se necessário
        } catch (error) {
            console.error('Erro ao configurar o player: ', error);
            // Implemente uma maneira de lidar com o erro aqui. Seja fornecendo feedback ao usuário, tentando novamente ou padrão seguro.
        }
    };

    const tocarConjugacao = async () => {
        let arquivoAudio, arquivoAudio0, urlAudio, urlAudio0,urlAudio00;
        let ActivityConfig = JSON.parse(localStorage.getItem('config'));

        if (ActivityConfig.PaisAudio === "Portugal"){
            arquivoAudio = "p" + arquivoRef.current;
            arquivoAudio0 = "p0" + arquivoRef.current;
        } else {
            arquivoAudio = "b" + arquivoRef.current;
            arquivoAudio0 = "b0" + arquivoRef.current;
        }

        urlAudio = process.env.PUBLIC_URL + `/raw/${arquivoAudio}.mp3`;
        urlAudio0 = process.env.PUBLIC_URL + `/raw/${arquivoAudio0}.mp3`;
        urlAudio00 = process.env.PUBLIC_URL + `/raw/virada.mp3`;
        // Defina audiosRef.current aqui
        const construirVetores = async () => {
            let playCount=0;
            audiosUrlRef.current=[];
            audiosRef.current=[];
            while (playCount < ActivityConfig.NumeroAudios) {
                if(ActivityConfig.LerTempo==="Nunca"){
                    audiosUrlRef.current.push(urlAudio);
                } else if((ActivityConfig.LerTempo==="Uma Vez")&&playCount===0){
                    audiosUrlRef.current.push(urlAudio0,urlAudio);
                } else if((ActivityConfig.LerTempo==="Uma Vez")&&playCount>0){
                    audiosUrlRef.current.push(urlAudio);
                } else{
                    audiosUrlRef.current.push(urlAudio0,urlAudio);
                }
                playCount++;
            }
            console.log(3999);
            if (ActivityConfig.NumeroAudios==="0"){
                console.log(4000);
                audiosUrlRef.current=[urlAudio00];
                console.log(4001);
            }
            audiosRef.current = audiosUrlRef.current.map((url) => ({
                name: 'Tempos Verbais',
                artist: 'Leonardo Pereira',
                url: url,
            }));

            await playerNewList(audiosRef.current);
        };
        const tocarAudios = async () => {
          const speed = ActivityConfig.VelocidadeAudio / 100;
          await playerSpeed(speed);
          await playerPlayAndWait();
        };
        await pararAudios();
        await construirVetores();
        await tocarAudios();

    };

    ////Funções do quadro dos seguidores de paradigma

    const atualizarVerbosS = async () => {
        console.log(11);
        atualizarVetorVerbosS();
        console.log(12);
        console.log(listaVerboSRef.current);
        console.log(ordemVerbosSRef.current);
        verboSRef.current = listaVerboSRef.current[ordemVerbosSRef.current];
        console.log(13);
        console.log(verboSRef.current);
        verboSRef.current = Terminacoes(verboSRef.current)+"\u00A0".repeat(4)+Pagina(verboSRef.current);
        console.log(14);
        arquivoSRef.current = listaVerboSRef.current[ordemVerbosSRef.current].substring(0,5);
        console.log(15);
        paginaSRef.current = Pagina(listaVerboSRef.current[ordemVerbosSRef.current]);
        console.log(16);
        ordemVerbosSRef.current = listaVerboSRef.current.findIndex(paradigmaX => paradigmaX === listaVerboSRef.current[ordemVerbosSRef.current]);
        console.log(17);
    }

    const forceRenderLocalA = async () => {
        //hasRunRef.current = false; // Atualize hasRunRef para false
        setRender(X => X + 1); // Força a renderização
    };

    ////Funções para ambos os quadros
    const aplicarMudanca = async () => {
        switch (selection) {
            case 1:
                titleRef.current="REGULARES BÁSICOS";
                conjugacaoVerbosRef.current=verbos1;
                numeroTemposRef.current=12;
                conjugacaoTemposRef.current=tempos1;
                numeroVerbosRef.current=3;
                break;
            case 2:
                titleRef.current="REGULARES PARTICULARES";
                conjugacaoVerbosRef.current=verbos2;
                numeroTemposRef.current=12;
                conjugacaoTemposRef.current=tempos2;
                numeroVerbosRef.current=5;
                break;
            case 3:
                titleRef.current="REGULARES ABUNDANTES";
                conjugacaoVerbosRef.current=verbos3;
                numeroTemposRef.current=12;
                conjugacaoTemposRef.current=tempos3;
                numeroVerbosRef.current=7;
                break;
            case 4:
                titleRef.current="IRREGULARES ESPECIAIS";
                conjugacaoVerbosRef.current=verbos4;
                numeroTemposRef.current=12;
                conjugacaoTemposRef.current=tempos4;
                numeroVerbosRef.current=8;
                break;
            case 5:
                titleRef.current="IRREGULARES FRACOS";
                conjugacaoVerbosRef.current=verbos5;
                numeroTemposRef.current=12;
                conjugacaoTemposRef.current=tempos5;
                numeroVerbosRef.current=9;
                break;
            case 6:
                titleRef.current="IRREGULARES FORTES";
                conjugacaoVerbosRef.current=verbos6;
                numeroTemposRef.current=12;
                conjugacaoTemposRef.current=tempos6;
                numeroVerbosRef.current=11;
                break;
            case 7:
                titleRef.current="IRREGULARES FORTÍSSIMOS";
                conjugacaoVerbosRef.current=verbos7;
                numeroTemposRef.current=12;
                conjugacaoTemposRef.current=tempos7;
                numeroVerbosRef.current=6;
                break;
            default:
                titleRef.current="Regulares e Irregulares";
        }

        // Chame as funções após a atualização do estado
        console.log(1);
        await atualizarTempos();
        console.log(2);
        await atualizarVerbosS();
        console.log(3);
        await forceRenderLocalA();
        await tocarConjugacao();
        console.log(4);
    }

    const forceRender = async () => {
        //hasRunRef.current = false; // Atualize hasRunRef para false
        await aplicarMudanca();
        setRender(X => X + 1); // Força a renderização
    };

    const forceRenderLocal = () => {
        //hasRunRef.current = false; // Atualize hasRunRef para false
        setRender(X => X + 1); // Força a renderização
    };

    //// Botões do quadro principal
    const handleTemposAnteriorClick = async (saltar) => {
        let verboAnterior = Verbos(
            conjugacaoVerbosRef.current[ordemVerbosRef.current],
        );
        let newOrdemVerbos = ordemVerbosRef.current - 1;

        if (cBFixarVerboRef.current) {
            if (
                newOrdemVerbos < 0 ||
                verboAnterior !== Verbos(conjugacaoVerbosRef.current[newOrdemVerbos])
            ) {
                newOrdemVerbos += numeroTemposRef.current;
            }
        }

        if (newOrdemVerbos < 0) {
            newOrdemVerbos = conjugacaoVerbosRef.current.length - 1;
        }

        ordemVerbosRef.current = newOrdemVerbos;
        if (bPausarRef.current) {
            bPausarRef.current = false;
        }
        if (saltar===true) {
        } else {
            await forceRender();
        }
    };

    const handleTemposPosteriorClick = async (saltar) => {
        let verboAnterior = Verbos(
            conjugacaoVerbosRef.current[ordemVerbosRef.current],
        );
        let newOrdemVerbos = ordemVerbosRef.current + 1;
        if (cBFixarVerboRef.current) {
            if (
                newOrdemVerbos >= conjugacaoVerbosRef.current.length ||
                verboAnterior !== Verbos(conjugacaoVerbosRef.current[newOrdemVerbos])
            ) {
                newOrdemVerbos -= numeroTemposRef.current;
            }
        }
        if (newOrdemVerbos >= conjugacaoVerbosRef.current.length) {
            newOrdemVerbos = 0;
        }

        ordemVerbosRef.current = newOrdemVerbos;
        if (bPausarRef.current) {
            bPausarRef.current = false;
        }
        if (saltar===true) {
        } else {
            await forceRender();
        }
    };

    const handleVerbosAnteriorClick = async (saltar) => {
        let tempoAnterior = Tempos(
            conjugacaoTemposRef.current[ordemTemposRef.current],
        );
        let newOrdemTempos = ordemTemposRef.current - 1;

        if (cBFixarTempoRef.current) {
            if (
                newOrdemTempos < 0 ||
                tempoAnterior !== Tempos(conjugacaoTemposRef.current[newOrdemTempos])
            ) {
                newOrdemTempos += numeroVerbosRef.current;
            }
        }

        if (newOrdemTempos < 0) {
            newOrdemTempos = conjugacaoTemposRef.current.length - 1;
        }

        ordemTemposRef.current = newOrdemTempos;
        if (bPausarRef.current) {
            bPausarRef.current = false;
        }
        await atualizarVerbos();
        if (saltar===true) {
        } else {
            await forceRender();
        }
    };

    const handleVerbosPosteriorClick = async (saltar) => {
        let tempoAnterior = Tempos(
            conjugacaoTemposRef.current[ordemTemposRef.current],
        );
        let newOrdemTempos = ordemTemposRef.current + 1;

        if (cBFixarTempoRef.current) {
            if (
                newOrdemTempos >= conjugacaoTemposRef.current.length ||
                tempoAnterior !== Tempos(conjugacaoTemposRef.current[newOrdemTempos])
            ) {
                newOrdemTempos -= numeroVerbosRef.current;
            }
        }

        if (newOrdemTempos >= conjugacaoTemposRef.current.length) {
            newOrdemTempos = 0;
        }

        ordemTemposRef.current = newOrdemTempos;
        if (bPausarRef.current) {
            bPausarRef.current = false;
        }
        await atualizarVerbos();
        if (saltar===true) {
        } else {
            await forceRender();
        }
    };

    const handleFixarVerboClick = () => {
        cBFixarVerboRef.current = !cBFixarVerboRef.current;
        forceRenderLocal();
    };


    const handleFixarTempoClick = () => {
        cBFixarTempoRef.current = !cBFixarTempoRef.current;
        forceRenderLocal();
    };


    const handlePassarVerbosClick = async () => {

        cBSPassarVerboRef.current=!cBSPassarVerboRef.current;
        await forceRenderLocalA();
        if (cBSPassarVerboRef.current) {
            await handleVerbosAnteriorClick(true);
        }
        while (cBSPassarVerboRef.current) {
            cBSPassarTempoRef.current = false;
            bRepetirRef.current = false;
            bPausarRef.current = false;
            await pararAudios();
            await handleVerbosPosteriorClick(false);
            await forceRenderLocalA();
        }
    };

    const handlePassarTemposClick = async () => {
        cBSPassarTempoRef.current=!cBSPassarTempoRef.current;
        await forceRenderLocalA();
        if (cBSPassarTempoRef.current) {
            await handleTemposAnteriorClick(true);
        }
        while (cBSPassarTempoRef.current) {
            cBSPassarVerboRef.current = false;
            bRepetirRef.current = false;
            bPausarRef.current = false;
            await pararAudios();
            await handleTemposPosteriorClick(false);
            await forceRenderLocalA();
        }
    };

    const handlePausarClick = async () => {
        const isPlaying = await playerIsPlaying();
        const isPaused = await playerIsPaused();

        if (isPlaying) {
            bPausarRef.current = true;
            await playerPause();
        } else if (isPaused) {
            bPausarRef.current = false;
            await playerPlay();
        }

        await forceRenderLocalA();
    };

    const handleExitClick = async () => {
        if (cBSPassarVerboRef.current) {
            await handlePassarVerbosClick();
        }
        if (cBSPassarTempoRef.current) {
            await handlePassarTemposClick();
        }
        if (bRepetirRef.current) {
            await handleRepetirClick();
        }
        await pararAudios();
        goBack();
    };

    const handleRepetirClick = async () => {
        bRepetirRef.current = !bRepetirRef.current
        await forceRenderLocalA();
        if (bRepetirRef.current) {
            cBSPassarTempoRef.current = false;
            cBSPassarVerboRef.current = false;
            bPausarRef.current = false;
        }
        while (bRepetirRef.current) {
            await forceRender();
        }
    };

    //Botões dos seguidores de paradigma
    const handleSeguidorAnteriorClick = async () => {
        let newOrdemVerbosS =ordemVerbosSRef.current - 1;

        if (newOrdemVerbosS < 0) { newOrdemVerbosS = listaVerboSRef.current.length - 1; }

        ordemVerbosSRef.current= newOrdemVerbosS;
        await atualizarVerbosS();
        forceRenderLocal();
    };

    const handleSeguidorPosteriorClick = async () => {
        let newOrdemVerbosS =ordemVerbosSRef.current + 1;

        if (newOrdemVerbosS>= listaVerboSRef.current.length) { newOrdemVerbosS = 0; }
        ordemVerbosSRef.current= newOrdemVerbosS;
        await atualizarVerbosS();
        forceRenderLocal();
    };

    // Atualize os estados com base na seleção quando o componente for montado ou a seleção mudar
    useEffect(() => {
        if (!hasRunRef.current) {
            forceRenderLocal();
            aplicarMudanca().then();
            hasRunRef.current = true;
        }
        loadingRef.current = true;
        console.log('Render:',renderSt);
    });

    if (loadingRef.current===false) {
        return null; // Ou seu componente de carregamento personalizado
    }

    return (
        <div className="App">
            <div className="column left" style={{alignItems: 'center',  justifyContent: 'center', height: '100vh'}}>
                <table>
                    <tbody>
                    <tr style={{height: '5px'}}>
                        <td style={{height: '5px', hyphens: "none"}}>
                            <h3><a href="https://www.paypal.com/donate/?hosted_button_id=T5WKS4MVY7VDE" target="_blank" rel="noopener noreferrer">Doação:</a></h3>
                        </td>
                    </tr>
                    <tr style={{height: '5px'}}>

                        <td style={{height: '5px'}}>
                            <p><a href="https://www.paypal.com/donate/?hosted_button_id=T5WKS4MVY7VDE" target="_blank" rel="noopener noreferrer">
                                <img src={require(`${paypalIconRef.current}`)}  alt="Ícone do PayPal" style={{width: '200px', height: '200px'}}/>
                            </a></p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className="column center2"  style={{flexDirection: 'column', alignItems: 'center'}}>
                <h3 style= {{marginTop: '0', marginBottom: '0'}}>{verboSRef.current}</h3>
                <Image
                    src={process.env.PUBLIC_URL + `/drawable2/${arquivoSRef.current}.png`}
                    alt="Paradigmas"
                    onError={(e) => {e.target.onerror = null; e.target.src=require(`${logoRef.current}`)}}
                    style={{width: '260px', height: '459px', position: 'absolute', bottom: '45px'}}
                />
                <Table style={{position: 'absolute', bottom: '3px'}}>
                    <tbody>
                    <tr>
                        <td>
                            <Button id="irSeguidorAnterior" className='button5' onClick={handleSeguidorAnteriorClick} style={{marginRight: '20px', backgroundImage: `url(${previousIcon})`}}></Button>
                        </td>
                        <td>
                            <Button id="irSeguidorPosterior" className='button5' onClick={handleSeguidorPosteriorClick} style={{backgroundImage: `url(${nextIcon})`}}></Button>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </div>
            <div className="column center"  style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <h3 style={{marginTop: '0' }}>{titleRef.current}</h3> {/* Use o título baseado na seleção */}
                <Table style={{ margin: '0' }}>
                    <tbody>
                    <tr>
                        <td style={{display: 'flex', justifyContent: 'center'}}>
                            <Button id="fixarVerbo" className={cBFixarVerboRef.current ? 'button6On' : 'button6Off'}
                                    onClick={handleFixarVerboClick}
                                    style={{backgroundImage: `url(${fixarIcon})`}}></Button>
                            <span style={{width: '30px'}}></span>
                            <Button id="irVerboAnterior" className='button5' onClick={() => handleVerbosAnteriorClick(false)}
                                    style={{backgroundImage: `url(${previousIcon})`}}></Button>
                            <span style={{width: '30px'}}></span>
                            <Button id="irVerboPosterior" className='button5' onClick={() => handleVerbosPosteriorClick(false)}
                                    style={{backgroundImage: `url(${nextIcon})`}}></Button>
                            <span style={{width: '30px'}}></span>
                            <Button id="passarVerbo" className={cBSPassarVerboRef.current ? 'button6On' : 'button6Off'}
                                    onClick={handlePassarVerbosClick}
                                    style={{backgroundImage: `url(${infiniteplayIcon})`}}></Button>
                        </td>
                    </tr>
                    </tbody>
                </Table>
                <h3 style={{marginBottom: '0'}}>{verboPRef.current}</h3>
                <h4 style={{marginTop: '0', marginBottom: '0', color: 'red'}}>{verboIRef.current}</h4>
                <LazyLoad
                    height={200}
                    offset={[-200, 200]}
                    placeholder={<img src={require(`${logoRef.current}`)} alt="Imagem da conjugação"
                                      style={{width: '257px', height: '266px', objectFit: 'contain', objectPosition: 'center'}} />}
                >
                    <div style={{width: '254px', height: '262px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Image
                            src={process.env.PUBLIC_URL + `/drawable/${arquivoRef.current}.png`}
                            alt="Imagem da conjugação"
                            onError={(e) => {e.target.onerror = null; e.target.src=require(`${logoRef.current}`)}}
                            style={{maxWidth: '100%', height: '155px'}}
                        />
                    </div>
                </LazyLoad>
                <h4 style={{ color: 'red'}}>{verboTRef.current}</h4>
                <h5>{tempoRef.current}</h5>
                <Table style={{ margin: '0' }}>
                    <tbody>
                    <tr>
                        <td style={{display: 'flex', justifyContent: 'center'}}>
                            <Button id="fixarTempo" className={cBFixarTempoRef.current ? 'button6On' : 'button6Off'}
                                    onClick={handleFixarTempoClick}
                                    style={{backgroundImage: `url(${fixarIcon})`}}></Button>
                            <span style={{width: '30px'}}></span>
                            <Button id="irTempoAnterior" className='button5' onClick={() => handleTemposAnteriorClick(false)}
                                    style={{backgroundImage: `url(${previousIcon})`}}></Button>
                            <span style={{width: '30px'}}></span>
                            <Button id="irTempoPosterior" className='button5' onClick={() => handleTemposPosteriorClick(false)}
                                    style={{backgroundImage: `url(${nextIcon})`}}></Button>
                            <span style={{width: '30px'}}></span>
                            <Button id="passarTempo" className={cBSPassarTempoRef.current ? 'button6On' : 'button6Off'}
                                    onClick={handlePassarTemposClick}
                                    style={{backgroundImage: `url(${infiniteplayIcon})`}}></Button>
                        </td>
                    </tr>
                    </tbody>
                </Table>
                <Table style={{position: 'absolute', bottom: '1px'}}>
                    <tbody>
                    <tr>
                        <td style={{display: 'flex', justifyContent: 'center'}}>
                            <Button id="parar" className={bPausarRef.current ? 'button3On' : 'button3Off'} onClick={handlePausarClick}
                                    style={{backgroundImage: `url(${pauseIcon})`}}></Button>
                        </td>
                        <td>
                            <Button id="sair" className='button2' onClick={handleExitClick}>Sair</Button>
                        </td>
                        <td style={{display: 'flex', justifyContent: 'center'}}>
                            <Button id="repetir" className={bRepetirRef.current ? 'button3On' : 'button3Off'} onClick={handleRepetirClick} style={{backgroundImage: `url(${infiniteplayIcon})`}}></Button>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </div>
            <div className="column right" style={{alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
                <table>
                    <tbody>
                    <tr style={{height: '5px'}}>
                        <td style={{height: '5px', hyphens: "none"}}>
                            <h3><a href="https://www.youtube.com/channel/UCgT4TW_NGL6sFU744v0y_zQ" target="_blank"
                                   rel="noopener noreferrer">Canal:</a></h3>
                        </td>
                    </tr>
                    <tr style={{height: '5px'}}>

                        <td style={{height: '5px'}}>
                            <p><a href="https://www.youtube.com/channel/UCgT4TW_NGL6sFU744v0y_zQ" target="_blank" rel="noopener noreferrer">
                                <img src={require(`${logoRef.current}`)} alt="Ícone do Canal" style={{width: '200px', height: '200px'}}/>
                            </a></p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ActivityBoard;
