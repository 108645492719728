import Amplitude from 'amplitudejs';
// import {wait} from "@testing-library/user-event/dist/utils";

console.log("AmplitudeJS Importado:", Amplitude); // Verifique todas as funções disponíveis

const musicList = [
    {
        name: 'Song 1',
        artist: 'Artist 1',
        url: process.env.PUBLIC_URL+'/raw/virada.mp3',
    },
];

Amplitude.init({
   songs: musicList,
});

// Funções auxiliares para controle do player

const isStopped = () => {
        const state = Amplitude.getPlayerState();
        return state === 'stopped';
};

// Função para verificar se o player está tocando
const playerIsPlaying = async () => {
    const state = Amplitude.getPlayerState();
    return state === 'playing';
};

const playerIsPaused = async () => {
    const state = Amplitude.getPlayerState();
    return state === 'paused';
};

const playerPlay = () => {
    Amplitude.play();
};

const playerPause = () => {
        Amplitude.pause();
};

const playerPlayAndWait = async () => {
    playerPlay();

    return new Promise((resolve) => {
        const checkPlayerState = () => {
            if (isStopped()) {
                resolve();
            } else {
                setTimeout(checkPlayerState, 100);
            }
        };

        checkPlayerState();
    });
};

// Nova função para definir a velocidade do áudio
const playerSpeed = (speed) => {
    if (typeof speed === 'number' && speed > 0) {
        Amplitude.setPlaybackSpeed(speed);
    } else {
        console.error('Velocidade inválida. Deve ser um número maior que 0.');
    }
};

// Nova função para mudar a lista de músicas
const playerNewList = async (newMusicList) => {
    if (Array.isArray(newMusicList) && newMusicList.length > 0) {
        return new Promise((resolve, reject) => {
            try {
                Amplitude.init({
                    songs: newMusicList,
                });
                resolve();  // Considera a inicialização síncrona
            } catch (error) {
                reject(error);
            }
        });
    } else {
        console.error('Lista de músicas inválida. Deve ser um array não vazio.');
        return Promise.reject('Lista de músicas inválida.');
    }
};

const playerStopAndReset = async () => {
    //Amplitude.playSongAtIndex(0);  // Define a primeira música como ativa
    await Amplitude.stop();  // Para imediatamente para garantir que não comece a tocar
    //await wait(100); // Pequeno delay para garantir que a ação foi concluída

    //Amplitude.setSongPlayedPercentage(0);  // Reinicia a primeira música no início
    //await wait(100); // Pequeno delay para garantir que a música foi reiniciada
};

export {playerNewList, playerSpeed, playerPlayAndWait, playerPause, playerPlay, playerStopAndReset, playerIsPlaying, playerIsPaused};

